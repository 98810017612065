import React from 'react'
import Photo7 from '../assets/IMG_4434.JPEG'
import Photo10 from '../assets/IMG_5390.jpg'
const Registry = () => {
  return (
    
    <div className='grid grid-cols-1 md:grid-cols-3 gap-4 p-4 justify-items-center pb-8'>
      <div className='float-right'>
          <img src={Photo7} className='rounded-2xl' alt='' height='400px' width='400px'/>
      </div>
      <div>
      <div id='Registry' className='text-center py-10'>
      <h3 className='text-5xl font-dancing text-burgundy pb-12'>
        Registry
      </h3>
      <p>
        We have registries on The Knot as well as Amazon. Our registries can be found&nbsp; 
        <a href='https://registry.theknot.com/christopher-conley-lauren-miller-october-2024-ok/64088469' rel='noreferrer'
        className='underline text-bold text-burgundy hover:text-dark-burgundy' target='_blank'>
          here  
        </a>
      </p>
    </div>
      </div>
      <div className='float-right'>
          <img src={Photo10} className='rounded-2xl' alt='' height='400px' width='400px'/>
      </div> 
    </div>
  )
}

export default Registry