import React from 'react'

import Photo11 from '../assets/IMG_5478_crop.jpeg'
import Photo4 from '../assets/IMG_2953.jpeg'

const Event = () => {
  return (
    <div className='p-4 text-center pb-12' id='Event'>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-4 p-4 justify-items-center pb-8'>
        <div className='float-right'>
            <img src={Photo11} className='rounded-2xl' alt='' height='400px' width='400px'/>
        </div>
        <div>
          <h3 className='text-5xl font-dancing text-burgundy pb-8'>
              Event Details
          </h3> 
          <div>
            {/*Events*/}
            <div>
              <p>OCTOBER 31ST, 2024</p>
              <p>6:30 PM</p>
              <p>The Harn Homestead</p>
              <p>1721 N. Lincoln Blvd, Oklahoma City, OK 73105</p>
              <p>The reception will start shortly after the ceremony is complete and will be held at the same location.</p>
              <p>Please wear black, burgundy, or burnt-orange. No costumes.</p>
            </div>
          </div>
        </div>
        <div className='float-right'>
            <img src={Photo4} className='rounded-2xl' alt='' height='400px' width='400px'/>
        </div> 
      </div>
    </div>
  )
}

export default Event