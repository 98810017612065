import React from 'react'
import Main from '../assets/couple.jpg'
import Photo2 from '../assets/IMG_1880.jpeg'


 
//begin About
const About = () => {
  return (
    <div id='About'>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-4 p-4 justify-items-center pb-8'>
            <div className='float-right'>
                <img src={Photo2} className='rounded-2xl' alt='' height='400px' width='400px'/>
            </div>
            <div className='text-center pb-10'>
                <h3 className='text-5xl font-dancing text-burgundy pb-8'>
                    From Us to You
                </h3>
                <p>Dear Family and Friends,</p>
                <p>
                We are super excited to invite you to join us at our wedding on October 31, 2024 in Oklahoma City, Oklahoma. We cannot wait to see you on our wedding day,
                to share our love with you, and to create memories with everyone that will last a lifetime.
                </p>
                <p>
                This website is something we have put together to provide event details as well as suggestions for lodging. Please check back over the months as for any updates 
                that may be made.
                </p>
                <p className='py-2'>Can't wait to celebrate the start of our new life with you!</p>
                <p className='py-2'>All our love,</p>
                <p className='font-dancing text-2xl pt-2 pb-6'>Lauren & Christopher</p>
                <hr />
                <h3 className='text-5xl text-center text-burgundy font-dancing py-4'>
                    About Us
                </h3>
                <p className='px-4'>
                    We have been together since May 11, 2023. Even though our time with each other has been short we have already had plenty of 
                    life events that have shown our values for life. Whether our adventures are laid back and relaxing and playing a game or sitting on the edge
                    of our seats while watching a horror movie, we will always do it together.
                </p>
            </div>
            <div className='float-right'>
                <img src={Main} className='rounded-2xl' alt='' height='400px' width='400px'/>
            </div>
        </div>
    </div>
  )
}

export default About