import React from 'react';
import Timer from './Timer';

const Header = () => {
    const {days, hours, minutes, seconds} = Timer("2024-10-31T18:30:00")
  return (
    <div className='text-center bg-black text-white py-24'  id='home'>
        <h3 className='text-4xl font-dancing pb-5'>
            The Wedding of
        </h3>
        <h1 className='text-7xl text-center text-burgundy font-dancing py-4'>
            Lauren Shuck <span className='text-2xl'>&</span> Christopher Conley
        </h1>
        <div className='pt-4'>
            <p>{days} Days, {hours} Hours, {minutes} Minutes, {seconds} Seconds - until the party starts!</p>
        </div>
    </div>
  )
}

export default Header