import {React, useState} from 'react';

const Nav = () => {
    let Links = [
        {name:"ABOUT", link:"#About"},
        {name:"EVENT", link:"#Event"},
        {name:"ACCOMMODATIONS", link:"#Accommodations"},
        {name:"REGISTRY", link:"#Registry"},
    ];
    let [open,setOpen] = useState(false);
  return (
    <div className='shadow-lg w-full fixed top-0 left-0 bg-black text-white'>
        <div className='md:flex items-center justify-between p-2'>
            <div className='font-bold flex items-center font-dancing'>
                <a href='/'>
                <span className='text-2xl mr-1 p-2'>
                    L&C
                </span>
                </a>
            </div>
            <div onClick={()=>setOpen(!open)} className='text-3xl absolute right-8 top-2 cursor-pointer md:hidden'>
            <ion-icon name={open ? 'close' : 'menu'}></ion-icon>  
            </div>
            <ul className={`md:flex bg-black md:items-center absolute md:static md:z-auto z-0 left-0 w-full md:w-auto md:pl-0 pl-9 
                    transition-all duration-500 ease-in ${open ? 'top-12':'top-[-490px]'}`}>
                {Links.map((link)=>(
                    <li key={link.name} className='md:ml-8 text-xl md:my-0 my-7 font-sans'>
                        <a href={link.link} className='hover:text-burgundy'>{link.name}</a>
                    </li>
                ))}  
            </ul>
        </div>
    </div>
  )
}

export default Nav