import React from 'react'

const Footer = () => {
  return (
    <div className='flex justify-center'>
        <p className='text-xs'>
            Created for & Dedicated to, with much love, my future wife. - Christopher Conley - 2023
        </p>
    </div>
  )
}

export default Footer