import Nav from './components/Nav';
import Header from './components/Header';
import About from './components/About';
import Registry from './components/Registry';
import Footer from './components/Footer';
import Event from './components/Event';
import Accommodations from './components/Accommodations';

function App() {
  return (
    <div className="bg-black text-white">
      <Nav />
      <Header />
      <About />
      <Event />
      <Accommodations />
      <Registry />
      <Footer />
    </div>
  );
}

export default App;
