import React from 'react'
import Photo3 from '../assets/IMG_2599.JPEG'
import Photo5 from '../assets/IMG_3134.jpeg'
import Photo6 from '../assets/IMG_3977.JPEG'
import Photo8 from '../assets/IMG_4607.jpeg'

const Accommodations = () => {
  return (
    <div className='p-4 text-center' id='Accommodations'>
        <h3 className='text-5xl font-dancing text-burgundy pb-12'>
            Accommodations
        </h3>
        <h5 className='text-xl pb-2'>
          Lodging Near Venue
        </h5>
        <div className='grid grid-cols-1 md:grid-cols-5 mx-8 pt-4 pb-10 justify-items-center'>
          <div>
            <img src={Photo3} className='rounded-2xl' alt='' height='200px' width='200px'/>
          </div>
          <div className=''>
            <p className='text-l font-bold'>Wyndam Grand Oklahoma City Downtown</p>
            <p>10 N Broadway Ave</p>
            <p>Oklahoma City,OK 73102</p>
            <p>405-228-8000</p>
            <a className='hover:text-dark-burgundy text-burgundy  underline' target='_blank' rel='noreferrer' href='https://www.wyndhamhotels.com/wyndham-grand/oklahoma-city-oklahoma/wyndham-grand-oklahoma-city-downtown/overview'>
              Their Website
            </a>
          </div>
          <div className=''>
            <p className='text-l font-bold'>Hampton Inn & Suites Oklahoma City-Bricktown</p>
            <p>300 E Sheridan Ave</p>
            <p>Oklahoma City,OK 73104</p>
            <p>405-232-3600</p>
            <a className='hover:text-dark-burgundy text-burgundy  underline' target='_blank' rel='noreferrer' href='https://www.hilton.com/en/hotels/okcbthx-hampton-suites-oklahoma-city-bricktown/'>
              Their Website
            </a>
          </div>
          <div className=''>
            <p className='text-l font-bold'>Country Inn & Suites by Radisson, Oklahoma City - Bricktown, OK</p>
            <p>931 E Reno Ave</p>
            <p>Oklahoma City,OK 73104</p>
            <p>405-900-5441</p>
            <a className='hover:text-dark-burgundy text-burgundy underline' target='_blank' rel='noreferrer' href='https://www.choicehotels.com/oklahoma/oklahoma-city/country-inn-suites-hotels/ok397'>
              Their Website
            </a>
          </div>
          <div>
            <img src={Photo5} className='rounded-2xl' alt='' height='200px' width='200px'/>
          </div>
        </div>
        <hr />
        <h5 className='text-xl pb-2 pt-10'>
          Lodging Near Family
        </h5>
        <div className='grid grid-cols-1 md:grid-cols-5 mx-8 pt-4 justify-items-center'>
          <div>
            <img src={Photo6} className='rounded-2xl' alt='' height='200px' width='200px'/>
          </div>
          <div className=''>
            <p className='text-l font-bold'>Country Inn & Suites by Radisson, Oklahoma City - Quail Springs, OK</p>
            <p>13501 Memorial Park Dr</p>
            <p>Oklahoma City,OK 73120</p>
            <p>405-286-3555</p>
            <a className='hover:text-dark-burgundy text-burgundy  underline' target='_blank' rel='noreferrer' href='https://www.choicehotels.com/oklahoma/oklahoma-city/country-inn-suites-hotels/ok396'>
              Their Website
            </a>
          </div>
          <div className=''>
            <p className='text-l font-bold'>Holiday Inn Express & Suites Oklahoma City NW-Quail Springs</p>
            <p>3520 NW 135th St</p>
            <p>Oklahoma City,OK 73120</p>
            <p>405-751-8900</p>
            <a className='hover:text-dark-burgundy text-burgundy  underline' target='_blank' rel='noreferrer' href='https://www.ihg.com/holidayinnexpress/hotels/us/en/oklahoma-city/okcpa/hoteldetail?cm_mmc=GoogleMaps-_-EX-_-US-_-OKCPA'>
              Their Website
            </a>
          </div>
          <div className=''>
            <p className='text-l font-bold'>avid hotel Oklahoma City - Quail Springs</p>
            <p>2700 NW 138th St</p>
            <p>Oklahoma City,OK 73134</p>
            <p>405-608-0900</p>
            <a className='hover:text-dark-burgundy text-burgundy underline' target='_blank' rel='noreferrer' href='https://www.ihg.com/avidhotels/hotels/us/en/oklahoma-city/okcav/hoteldetail'>
              Their Website
            </a>
          </div>
          <div>
            <img src={Photo8} className='rounded-2xl' alt='' height='200px' width='200px'/>
          </div>
        </div>
    </div>
  )
}

export default Accommodations